.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  background: #f8fbfe;
}

.logos {
  float: left;
  padding: 5px;
}
.logos label {
  display: none;
  font-size: 14px;
}
.logos a {
  display: block;
}
.logos img {
  max-width: 180px;

}
.logos img.cdf {
  max-width: 170px;
}

.wrapper {
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;
}



.form-container{
  margin-top: 50px;
  position: relative;
  height: 600px;

}

.conversational-form {
  /* border: 2px solid #142888;   */
  border-radius: 13px;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05)
}

cf-chat-response.user text>p {
  background: #142888;
}

.navbar-brand {
  font-weight: bold;
  color: #142888;
  font-size: 30px;
  margin-top: 10px;
}

.navbar-brand {
  color: #00458C;
}

.navbar-brand .covid {
  color: #C81422;
}


.navbar-brand h1 {
  font-weight: 700;
  font-size: 30px;
}

.navbar-brand .badge {
  font-size: 14px;
  position: relative;
  top: -5px;
}

.navbar-brand .badge-light {
  background-color: #e5e6ea;
}

*[tag-name=kind] cf-list {
  height: 200px !important;
}
*[tag-name=kind] cf-button.cf-button.animate-in {
  display: block !important;
  max-width: 530px;
}

*[tag-name=kind] cf-list-button {
  display: none;
}

*[tag-name^="blue"] p {
  background-color: #cce5ff;
}
*[tag-name^="orange"] p {
  background-color: #fff3cd;
}

*[tag-name^="red"] p {
  background-color: #f8d7da;
}

/* thumb {
  background-position: 50% 40% !important;
  background-size: 70% !important;
  opacity: 0.7 !important;
} */

.inputWrapper {
  display: none;
}

*[tag-name=departement] .inputWrapper {
  display: block;
}

.subtitle {
  text-align: center;
  color: #142888;
  position: relative;
}

.last-update{
  position: absolute;
  right: 0;
  font-size: 11px;
  margin-top: 10px;
}


section {
  margin-top: 50px;
  position: relative;
}

h2 {
  text-align:center;
}


.contact-us .btn{
  margin-left:0;
  padding-left: 0;
  padding-bottom: 0;
}

.contact-us span {
  padding: 8px;
  padding-bottom: 0;
  margin-left:0;
  padding-left: 0;
  line-height: 24px;
  font-size: 16px;
  display: inline-block;
}

.anchor-link-container {
  text-align: center;
  margin-bottom: 15px;
  display: none;
}



.conversational-form.done cf-chat-response a, .conversational-form.done cf-chat-response a:active {
  color: #007bff;
  pointer-events: auto;
}


.conversational-form.done cf-chat-response a:hover {
  color: #0056b3;
}



@media screen and (max-width: 800px) {

  .logos {
    float: none;
    border-bottom: 1px solid #ddd;
  }
  .logos a {
    display: inline;
  }
  .logos img {
    max-height: 50px;
  }

  .logos img.spotimplant {
    float: right;
    max-height: 40px;
    margin-top: 5px;
  }


  .navbar {
    padding: 0;
  }
  .navbar-brand {
    font-size: 22px;
    padding: 0;
    padding-bottom: 15px;
  }

  .navbar-brand h1{
    font-size: 22px;
  }
  .navbar-brand .badge{
    font-size: 12px;
    display: inline-block;
  }
  .anchor-link-container {
    display: block;
  }
  p, li {
    font-size: 15px;
  }

  *[tag-name=kind] cf-button.cf-button.animate-in {
    display: block !important;
    max-width: 270px;
  }

  *[tag-name=kind] cf-list {
    height: 230px !important;
  }

  .cf-button>div {
    margin: 10px 16px;
  }

  .last-update {
    display: none;
  }

  .form-container {
    margin-top: 0;
    height: 560px;
  }
}


.chat-header{
  width:  100%;
  background: #142888;
  position: relative;
  z-index: 99999;
  color: white;
  text-align: center;
  padding: 10px;
}

.chat-header h1 {
  font-size: 16px;
  margin:0;
}