//@import "conversational-form/src/styles/mixins/cf-mixins";

/*
    Globals
*/
$cf-base-font-size: 14px !default; // Default is 12px. Positions and sizes will be relative to this size.

$cf-color-background: #fff !default;
$cf-color-primary: #142888 !default;
$cf-color-secondary: #f3f4f6 !default;
$cf-color-error: #fe8d84 !default;

$cf-font-family: 'Helvetica Neue','Neue Helvetica W01',Helvetica,Arial,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol',sans-serif !default;

//@import "conversational-form/src/styles/conversational-form";

cf-chat-response.user text>p {
    background: $cf-color-primary !important;
  }

  cf-chat-response.can-edit:hover text>p {
    background: darken($cf-color-primary, 10%)!important;
  }

.cf-button, cf-radio {
    color: $cf-color-primary !important;
    border-color: $cf-color-primary !important;
}


.cf-button:focus, .cf-button:focus:not([checked=checked]), .cf-button:hover, .cf-button:hover:not([checked=checked]) {
    background: $cf-color-primary !important;
    color: #e8e9eb !important;
}

.chatbot-container .cf-button[checked=checked]:not(:focus):not(:hover):not(.highlight),.chatbot-container .cf-button[selected=selected]:not(:focus):not(:hover):not(.highlight) {
  background: $cf-color-primary !important;
}

.chatbot-container .cf-button.cf-checkbox-button[checked=checked],.chatbot-container .cf-button.cf-checkbox-button[selected=selected] {
  background: $cf-color-primary !important;
}

.chatbot-container cf-input-button.cf-input-button {
  background: $cf-color-primary !important;
}