*[tag-name=severe] cf-button.cf-button.animate-in, *[tag-name=symptoms] cf-button.cf-button.animate-in, *[tag-name=symptoms_2] cf-button.cf-button.animate-in {
    display: block !important;
    max-width: 375px;
  }

*[tag-name=severe] cf-list {
  height: 250px !important;
}

*[tag-name=symptoms] cf-list, *[tag-name=symptoms_2] cf-list , *[tag-name=precondition_2] cf-list{
height: 180px !important;
}

*[tag-name=area] cf-button.cf-button.animate-in, *[tag-name=exposure] cf-button.cf-button.animate-in {
  display: block !important;
  max-width: 400px;
}


*[tag-name=area] cf-button.cf-button.animate-in {
  display: block !important;
  max-width: 480px;
}

*[tag-name=area] cf-list, *[tag-name=exposure] cf-list{
height: 175px !important;
}

 *[tag-name=exposure] cf-list, *[tag-name=precondition] cf-list{
  height: 220px !important;
}

.covid *[tag-name=precondition] cf-button.cf-button.animate-in {
  display: block !important;
  max-width: 400px;
}

.covid *[tag-name="symptoms"] .inputWrapper, .covid *[tag-name="symptoms_2"] .inputWrapper, .covid *[tag-name="precondition"] .inputWrapper{
  display: block !important;
}

.covid *[tag-name=precondition_2] .inputWrapper{
  display: block !important;
}

.covid .inputWrapper textarea {
  opacity: 0 !important;
}
.covid .inputWrapper cf-input-button {
  left:0;
}

@media screen and (min-width: 1150px) {
  .covid *[tag-name=severe] cf-list {
    height: 270px !important;
  }

  .covid *[tag-name=symptoms] cf-list {
    height: 360px !important;
    }

  .covid *[tag-name=exposure] cf-list {
    height: 220px !important;
  }

}


@media only screen and (max-width : 500px) {
  *[tag-name=severe] cf-button.cf-button.animate-in, *[tag-name=symptoms] cf-button.cf-button.animate-in, *[tag-name=symptoms_2] cf-button.cf-button.animate-in, .covid *[tag-name=precondition] cf-button.cf-button.animate-in, .covid *[tag-name=precondition_2] cf-button.cf-button.animate-in, .covid *[tag-name=area] cf-button.cf-button.animate-in, .covid *[tag-name=exposure] cf-button.cf-button.animate-in {
    display: block !important;
    max-width: 300px;
  }
}

.covid-embed {
  position: fixed;
  width: 100%;
  height: 100%;
}

.cf-gradient{
  display: none;
}


.covid-embed .cf-input input[rows='1'], .covid-embed cf-input textarea[rows='1'] {
  height: 40px !important;
  min-height: 40px !important;
}


cf-input[tag-type=file] cf-input-button.cf-input-button, cf-input[tag-type=group] cf-input-button.cf-input-button, cf-input[tag-type=select] cf-input-button.cf-input-button{
  height: 40px!important;
  width: 80px;
  border-radius: 40px;
}


.covid-embed .chat-header {
  text-align: center;
}

.cf-icon-progress::after {
  content: 'Ok';
  color: white;
}

cf-input-button.cf-input-button div.cf-icon-progress {
  display: flex;
  justify-content: center;
  background-image: none!important;
  align-items: center;
}